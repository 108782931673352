import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@boseo/helper/methods";
import { SEO } from "gatsby-theme-core";
import { Layout, Header01, Footer01 } from "gatsby-theme-layout";
import {
    PageHeader01,
    Service05,
    Service06,
    About01,
    About06,
    Service07,
    Process03,
    ContactForm,
} from "gatsby-theme-container";
import { BreadCrumb03 } from "gatsby-theme-component";
import baseTheme from "../../themes/base-theme";

const WebMobilePage = ({ data }) => {
    const content = normalizedData(data?.page?.content || []);

    return (
        <Layout theme={baseTheme}>
            <SEO pageName="Web Mobile" />
            <Header01 hasBorder data={{ ...data.header, ...data.navigation }} />
            <BreadCrumb03 data={content?.["page-banner-section"]} />
            <Service05 data={content?.["service-section"]} />
            <About01 data={content?.["about-section"]} />
            <About06 data={content?.["about-section2"]} />
            <Service06
                sx={{ bg: "athens" }}
                data={content?.["service-section2"]}
            />
            <Process03 data={content?.["process-section"]} />
            <ContactForm data={{ contact_info: data.contact?.contact_info }} />
            <Footer01 data={{ ...data?.footer, ...data?.contact }} />
        </Layout>
    );
};

export const query = graphql`
    query WebMobilePageQuery {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
        page(title: { eq: "webmobile" }) {
            content {
                ...PageContent
            }
        }
    }
`;

WebMobilePage.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default WebMobilePage;
